import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  addEdge,
  Background,
  BackgroundVariant,
  Connection,
  Controls,
  EdgeTypes,
  MiniMap,
  Node,
  NodeTypes,
  ReactFlow,
  useNodesState,
  ReactFlowProvider,
  useOnSelectionChange,
  applyEdgeChanges,
  Edge,
  EdgeChange,
  ReactFlowInstance,
} from "reactflow";
import "reactflow/dist/style.css";
import { deepCopy, shiftElementNextTo } from "../../../../utils/otherUtils";
import {
  setChildNode,
  isInside,
  prepareNodesAndEdges,
  autoLayoutNodes,
} from "../../../../utils/flowUtils";
import ResizableGroupNode from "./ResizableGroupNode";
import DeviceNode from "./DeviceNode";
import MultiSelectionToolbar from "./MultiSelectionToolbar";
import ButtonEdge from "./ButtonEdge";
import { useDispatch, useSelector } from "react-redux";
import { setComponentID } from "../../../../features/componentSlice";
import { RootState } from "../../../../store";
import {
  ComponentProperties,
  GetValueAPIResponse,
} from "../../../../types/ldmResponseTypes";
import {
  changeParentGroup,
  changePreMeter,
  getComponentById,
  deleteComponentById,
  getDevicesByPreMeterId,
} from "../../../../utils/componentUtils";
import {
  removeDetachedNodeId,
  setComponentStructureForFlow,
} from "../../../../features/topologySlice";
import { decryptData } from "../../../../utils/cryptoUtils";
import { NodeCoordinate } from "../../../../bops.gen";
import { getPreferences } from "../../../../services/iamServiceAPI";

const nodeTypes: NodeTypes = {
  resizableGroup: ResizableGroupNode,
  device: DeviceNode,
};
const edgeTypes: EdgeTypes = {
  buttonEdge: ButtonEdge,
};

interface Props {
  setEditedProps: React.Dispatch<
    React.SetStateAction<{
      [id: string]: ComponentProperties;
    }>
  >;
  initialProps: {
    [id: string]: GetValueAPIResponse;
  };
  setRfInstance: React.Dispatch<
    React.SetStateAction<ReactFlowInstance | undefined>
  >;
  setHasDraggedNode: React.Dispatch<React.SetStateAction<boolean>>;
  setDraggedNodeIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const Flow = (props: Props) => {
  const dispatch = useDispatch();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const componentId = String(
    useSelector((state: RootState) => state.component.id)!
  );
  const { componentStructureForFlow } = useSelector(
    (state: RootState) => state.topology
  );
  const {
    setEditedProps,
    initialProps,
    setRfInstance,
    setHasDraggedNode,
    setDraggedNodeIds,
  } = props;
  const isRerenderRequired = useRef(true);

  useEffect(() => {
    if (!isRerenderRequired.current) {
      isRerenderRequired.current = true;
      return;
    }

    let { preparedNodes, preparedEdges } = prepareNodesAndEdges(
      componentStructureForFlow
    );

    const initializeFlow = async () => {
      try {
        preparedNodes = autoLayoutNodes(preparedNodes, preparedEdges);
        const preferences = await getPreferences();
        const topology = preferences.topology;

        if (topology) {
          const changedRfCoordinates: NodeCoordinate[] = decryptData(
            topology
          ) as NodeCoordinate[];

          const changedCoordinatesMap = new Map(
            changedRfCoordinates.map((rfCoordinates: NodeCoordinate) => [
              String(rfCoordinates.i),
              rfCoordinates,
            ])
          );

          preparedNodes = preparedNodes.map((node: Node) => {
            const changedNode = changedCoordinatesMap.get(node.id);

            if (changedNode) {
              return {
                ...node,
                position: changedNode.p,
                positionAbsolute: changedNode.A,
              };
            }

            return node;
          });
        }
        setNodes(preparedNodes);
        setEdges(preparedEdges);
      } catch (error) {
        console.info(error);
        // Auto layout
        preparedNodes = autoLayoutNodes(preparedNodes, preparedEdges);
        setNodes(preparedNodes);
        setEdges(preparedEdges);
      }
    };

    initializeFlow();
  }, [componentStructureForFlow, setNodes, setEdges]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === componentId
          ? { ...node, selected: true }
          : { ...node, selected: false }
      )
    );
  }, [componentId]); // eslint-disable-line

  const onConnect = useCallback(
    (connection: Connection) => {
      const sourceId = connection.source!;
      const targetId = connection.target!;
      if (edges.some((e) => e.target === targetId) || sourceId === targetId)
        return;

      const componentId = targetId;
      const propName = "pre_meter_id";
      const propInitialValue = initialProps[componentId]["editable"][propName];
      const propNewValue = Number(sourceId);

      isRerenderRequired.current = false;
      setEditedProps((editedProps) => {
        let editedPropsClone: typeof editedProps;
        editedPropsClone = deepCopy(editedProps);
        // If the edition is same with the initial version of the property
        if (propInitialValue === propNewValue) {
          delete editedPropsClone[componentId][propName]; // then there is no change for this prop.
          // If there is no change left on this component
          if (Object.keys(editedPropsClone[componentId]).length === 0)
            delete editedPropsClone[componentId]; // then there is no change for this component.
        }
        // If the edition is different than the initial version of the property
        else {
          // If first edit for this component
          if (!(componentId in editedPropsClone))
            editedPropsClone[componentId] = {};
          editedPropsClone[componentId][propName] = propNewValue;
        }
        return editedPropsClone;
      });
      dispatch(
        setComponentStructureForFlow(
          changePreMeter(
            Number(targetId),
            Number(sourceId),
            deepCopy(componentStructureForFlow)
          )
        )
      );
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            id: `e${connection.source}-${connection.target}`,
            zIndex: 1001,
            type: "buttonEdge",
          },
          eds
        )
      );
    },
    [
      edges,
      setEdges,
      initialProps,
      setEditedProps,
      dispatch,
      componentStructureForFlow,
    ]
  );

  const onEdgesDelete = (edges: Edge[]) => {
    if (edges[0].selected) {
      isRerenderRequired.current = false;
      const edgeId = edges[0].id;
      const dashIndex = edgeId.indexOf("-");
      const targetId = edgeId.slice(dashIndex + 1);
      const componentId = targetId;
      const propName = "pre_meter_id";
      const propInitialValue = initialProps[componentId]["editable"][propName];
      const propNewValue = null;
      setEditedProps((editedProps) => {
        let editedPropsClone: typeof editedProps;
        editedPropsClone = deepCopy(editedProps);
        // If the edition is same with the initial version of the property
        if (propInitialValue === propNewValue) {
          delete editedPropsClone[componentId][propName]; // then there is no change for this prop.
          // If there is no change left on this component
          if (Object.keys(editedPropsClone[componentId]).length === 0)
            delete editedPropsClone[componentId]; // then there is no change for this component.
        }
        // If the edition is different than the initial version of the property
        else {
          // If first edit for this component
          if (!(componentId in editedPropsClone))
            editedPropsClone[componentId] = {};
          editedPropsClone[componentId][propName] = propNewValue;
        }
        return editedPropsClone;
      });
      dispatch(
        setComponentStructureForFlow(
          changePreMeter(
            Number(targetId),
            null,
            deepCopy(componentStructureForFlow)
          )
        )
      );
    }
  };

  const onNodesDelete = useCallback(
    (deleted: Node[]) => {
      const deletedType = deleted[0].type;
      const deletedId = deleted[0].id;
      var updatedComponentStructureForFlow = deepCopy(
        componentStructureForFlow
      );
      if (deletedType === "device") {
        const preMeters = getDevicesByPreMeterId(
          Number(deletedId),
          deepCopy(updatedComponentStructureForFlow)
        );

        setEditedProps((editedProps) => {
          const editedPropsClone = { ...editedProps };
          if (!(deletedId in editedPropsClone)) {
            editedPropsClone[deletedId] = {};
          }
          editedPropsClone[deletedId]["state"] = 0;
          return editedPropsClone;
        });

        //update the preMeterId to null for the devices with the pre_meter_id of the deleted device.
        preMeters.forEach((preMeter) => {
          updatedComponentStructureForFlow = changePreMeter(
            preMeter.id,
            null,
            deepCopy(updatedComponentStructureForFlow)
          );
        });
      } else if (deletedType === "resizableGroup") {
        const deletedGroupId = deleted[0].id;
        const deletedParentNode = deleted[0].parentNode;
        // for each component in the deleted group check if it is a direct child of the deleted group
        // if so, update its parent group id to the parent group of the deleted group
        deleted.forEach((component) => {
          if (component.parentNode === deletedGroupId) {
            updatedComponentStructureForFlow = changeParentGroup(
              Number(component.id),
              Number(deletedParentNode),
              deepCopy(updatedComponentStructureForFlow)
            );

            setEditedProps((editedProps) => {
              const editedPropsClone = { ...editedProps };
              if (!(component.id in editedPropsClone)) {
                editedPropsClone[component.id] = {};
              }
              editedPropsClone[component.id]["parent_group_id"] =
                Number(deletedParentNode);
              return editedPropsClone;
            });
          }
        });

        setEditedProps((editedProps) => {
          const editedPropsClone = { ...editedProps };
          if (!(deletedId in editedPropsClone)) {
            editedPropsClone[deletedId] = {};
          }
          editedPropsClone[deletedId]["state"] = 0;
          return editedPropsClone;
        });
      }

      dispatch(
        setComponentStructureForFlow(
          deleteComponentById(
            Number(deleted[0].id),
            deepCopy(updatedComponentStructureForFlow)
          )
        )
      );
    },
    [setEditedProps, dispatch, componentStructureForFlow]
  );

  const onEdgesChange = (changes: EdgeChange[]) => {
    setEdges((eds) => applyEdgeChanges(changes, eds));
  };

  const onNodeDragStop = useCallback(
    (event: React.MouseEvent<Element>, draggedNode: Node) => {
      setHasDraggedNode(true);
      setDraggedNodeIds((draggedNodeIds) => {
        const newId = +draggedNode.id; // Convert the ID to a number
        return draggedNodeIds.includes(newId)
          ? draggedNodeIds
          : [...draggedNodeIds, newId];
      });
      for (const node of nodes.slice().reverse()) {
        if (
          draggedNode.parentNode ||
          draggedNode.id === node.id ||
          node.type !== "resizableGroup"
        ) {
          continue; // skip this node
        }
        if (isInside(draggedNode, node)) {
          let isAlreadyParent =
            getComponentById(+draggedNode.id, componentStructureForFlow)!
              .parentGroupId === Number(node.id);
          isRerenderRequired.current = false;
          const parentNode = node;
          setChildNode(draggedNode, parentNode);
          /* It's important that your parent nodes appear before their children in the
          nodes/defaultNodes array to get processed correctly.*/
          let newNodes = nodes.map((nodeItem) =>
            nodeItem.id === draggedNode.id ? draggedNode : nodeItem
          );
          if (newNodes.indexOf(parentNode) > newNodes.indexOf(draggedNode)) {
            newNodes = shiftElementNextTo(
              newNodes,
              parentNode,
              draggedNode,
              "before"
            );
          }
          dispatch(removeDetachedNodeId(draggedNode.id));
          if (!isAlreadyParent) {
            // Updates the object that stores the edited properties
            setEditedProps((editedProps) => {
              const componentId = +draggedNode.id;
              const propName = "parent_group_id";
              const propInitialValue =
                initialProps[componentId]["editable"][propName];
              const propNewValue = +parentNode.id;

              let editedPropsClone: typeof editedProps;
              editedPropsClone = deepCopy(editedProps);
              // If the edition is same with the initial version of the property
              if (propInitialValue === propNewValue) {
                delete editedPropsClone[componentId][propName]; // then there is no change for this prop.
                // If there is no change left on this component
                if (Object.keys(editedPropsClone[componentId]).length === 0)
                  delete editedPropsClone[componentId]; // then there is no change for this component.
              }
              // If the edition is different than the initial version of the property
              else {
                // If first edit for this component
                if (!(componentId in editedPropsClone))
                  editedPropsClone[componentId] = {};
                editedPropsClone[Number(draggedNode.id)][propName] =
                  propNewValue;
              }
              return editedPropsClone;
            });
          }
          dispatch(
            setComponentStructureForFlow(
              changeParentGroup(
                +draggedNode.id,
                +parentNode.id,
                deepCopy(componentStructureForFlow)
              )
            )
          );
          setNodes(newNodes);
          return;
        }
      }
    },
    [
      nodes,
      setNodes,
      initialProps,
      setEditedProps,
      dispatch,
      componentStructureForFlow,
      setHasDraggedNode,
      setDraggedNodeIds,
    ]
  );

  useOnSelectionChange({
    onChange: ({ nodes, edges }) => {
      if (nodes.length !== 0) dispatch(setComponentID(Number(nodes[0].id)));
    },
  });

  return (
    <ReactFlow
      onInit={setRfInstance}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onNodesDelete={onNodesDelete}
      onEdgesChange={onEdgesChange}
      onEdgesDelete={onEdgesDelete}
      onNodeDragStop={onNodeDragStop}
      onConnect={onConnect}
      selectNodesOnDrag={true}
      maxZoom={2}
      minZoom={0.25}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitView
      proOptions={{ hideAttribution: true }}
    >
      <Background variant={BackgroundVariant.Dots} />
      <Controls />
      <MultiSelectionToolbar />
      {nodes.length > 30 ?? (
        <MiniMap
          pannable
          nodeStrokeWidth={4}
          nodeStrokeColor={"#000"}
          nodeBorderRadius={20}
        />
      )}
    </ReactFlow>
  );
};

export default memo(function (props: Props) {
  return (
    <ReactFlowProvider>
      <Flow
        setEditedProps={props.setEditedProps}
        initialProps={props.initialProps}
        setRfInstance={props.setRfInstance}
        setHasDraggedNode={props.setHasDraggedNode}
        setDraggedNodeIds={props.setDraggedNodeIds}
      />
    </ReactFlowProvider>
  );
});
