import pako from "pako";
import { NodeCoordinateList, NodeCoordinate } from "../bops.gen";

function toBase64URL(base64: string): string {
  return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function fromBase64URL(base64url: string): string {
  base64url = base64url.replace(/-/g, "+").replace(/_/g, "/");
  // Add back padding
  while (base64url.length % 4) {
    base64url += "=";
  }
  return base64url;
}

export function encryptData(data: any) {
  let nodeCoordinateList = new NodeCoordinateList({ coordinates: data });
  data = data as NodeCoordinate[];
  const encoded = nodeCoordinateList.encode();
  // 2. Apply GZIP compression
  const gzipped = pako.gzip(encoded);
  // 3. Convert to base64url
  const base64String = btoa(
    String.fromCharCode.apply(null, gzipped as unknown as number[])
  );
  return toBase64URL(base64String);
}

export function decryptData(compressed: string) {
  // 1. Convert from base64url string to Uint8Array
  const base64String = fromBase64URL(compressed);
  const binary = atob(base64String);
  const bytes = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }
  // 2. GZIP decompress
  const decoded = pako.ungzip(bytes);
  // 3. Decode bebop
  const nodeCoordinateList = NodeCoordinateList.decode(decoded);
  return nodeCoordinateList.coordinates;
}
