import { useEffect, useState } from "react";
import { Loading } from "../components/Loading";
import { Navigate } from "react-router-dom";
import { isMobileApp } from "../utils/otherUtils";

const Logout = () => {
  const [loggedOut, setLoggedOut] = useState(false);

  useEffect(() => {
    localStorage.clear();
    setLoggedOut(true);
  }, []);

  if (!loggedOut || isMobileApp()) return <Loading variant="global" />;
  return <Navigate to="/login" />;
};

export default Logout;
