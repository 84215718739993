import dayjs from "dayjs";
import { Interval } from "../types/analyticsResponseTypes";

/**
 * Shifts element "A" to be placed after or before the element "B" in the
 * array without make any change on original "arr" array.
 * @param arr array which will be shifted
 * @param A the element which will be shifted
 * @param B the target element to use to shift A element
 * @param variant Will element "A" be placed "after" or "before" element "B"?
 * @returns shifted version of "arr" array
 */
export function shiftElementNextTo(
  arr: any[],
  A: any,
  B: any,
  variant: "before" | "after"
): any[] {
  if (!arr.includes(A) || !arr.includes(B))
    throw new Error("A veya B elemanları array içinde değil");

  if (A === B) throw new Error("A ve B elemanları aynı olamaz");

  const returnArr = arr.slice();

  const AIndex = returnArr.indexOf(A); // current A index
  returnArr.splice(AIndex, 1); // removes A

  const BIndex = returnArr.indexOf(B); // new B index after the remove operetion
  const targetIndex = variant === "after" ? BIndex + 1 : BIndex; // new A index
  returnArr.splice(targetIndex, 0, A); // Adds A to new A index

  return returnArr;
}

export function isInHourlyRange(
  item: [hour: number, minute: number],
  start: [hour: number, minute: number],
  end: [hour: number, minute: number]
) {
  let startTime = start[0] * 60 + start[1];
  let endTime = (start[0] > end[0] ? 24 * 60 : 0) + end[0] * 60 + end[1];
  let itemTime = (item[0] < start[0] ? 24 * 60 : 0) + item[0] * 60 + item[1];
  return itemTime >= startTime && itemTime < endTime;
}

/**
 * Maksimum 300 veri olacak şekilde interval ayarlar.
 */
export function getRequestInterval(
  startTime: number,
  endTime: number
): Interval {
  const startDate = dayjs.unix(startTime);
  const endDate = dayjs.unix(endTime);

  if (startDate >= endDate.subtract(5, "hour")) return "m1";
  else if (startDate >= endDate.subtract(75, "hour")) return "m15";
  else if (startDate >= endDate.subtract(300, "hour")) return "h1";
  else if (startDate >= endDate.subtract(900, "hour")) return "h3";
  else if (startDate >= endDate.subtract(3600, "hour")) return "h12";
  else if (startDate >= endDate.subtract(7200, "hour")) return "d1";
  else return "w1";
}

export async function wait(milliseconds: number) {
  await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export function isEmailValid(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export class ParentlessDeviceError extends Error {
  constructor(message: string) {
    super(message); // call the parent constructor
    this.name = "ParentlessDeviceError"; // set the name property
  }
}

export function intervalToSeconds(interval: Interval) {
  switch (interval) {
    case "s3":
      return 3;
    case "s15":
      return 15;
    case "m1":
      return 60;
    case "m15":
      return 15 * 60;
    case "h1":
      return 60 * 60;
    case "h3":
      return 3 * 60 * 60;
    case "h12":
      return 12 * 60 * 60;
    case "d1":
      return 24 * 60 * 60;
    case "w1":
      return 7 * 24 * 60 * 60;
  }
}

export function getHarmonicByIndex(
  index: number,
  type: "even" | "odd"
): string {
  return `h${2 * index + (type === "even" ? 2 : 1)}`;
}

export function isMobileApp() {
  return window.navigator.userAgent === process.env.REACT_APP_MOBILE_USER_AGENT;
}

export function isMaxWidthXL() {
  return isMobileApp() || window.innerWidth <= 1280;
}

export function deepCopy(obj: any) {
  return JSON.parse(JSON.stringify(obj)) as typeof obj;
}
