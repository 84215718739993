import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { baseClient } from "./axiosConfig";
import { login, updateAccess } from "./iamServiceAPI";
import { Buffer } from "buffer";
import { store } from "../store";
import { DeviceComponent, GroupComponent } from "../types/componentTypes";
import { GetValueAPIResponse } from "../types/ldmResponseTypes";
import { getValue } from "./ldmServiceAPI";
import { NavigateFunction } from "react-router-dom";

const SERVICES = process.env.REACT_APP_SERVICES!.split(",");

export async function updateAccessAndRetryRequest(
  requestConfig: AxiosRequestConfig,
  navigate: NavigateFunction
) {
  try {
    const response = await updateAccess(
      localStorage.getItem("refresh-token") ?? ""
    );
    if (response.status !== 200) throw new Error("Access update failed!");
    let areRulesSame = checkRules(
      localStorage.getItem("access-token") ?? "",
      response.data.access_token
    );
    if (!areRulesSame) return navigate("/logout");
    else {
      localStorage.setItem("access-token", response.data.access_token);
      requestConfig.headers = requestConfig.headers ?? {};
      requestConfig.headers.Authorization = response.data.access_token;
      baseClient.defaults.headers.common["Authorization"] =
        response.data.access_token;
      if (response.data.refresh_token !== undefined)
        localStorage.setItem("refresh-token", response.data.refresh_token);
      return baseClient(requestConfig);
    }
  } catch (error) {
    console.info(error);
    return navigate("/logout");
  }

  function checkRules(oldToken: string, newToken: string) {
    let oldRules = parseJwt(oldToken).tnt;
    let newRules = parseJwt(newToken).tnt;
    if (oldRules.length !== newRules.length) return false;
    for (let index = 0; index < oldRules.length; index++) {
      if (oldRules[index] !== newRules[index]) return false;
    }
    return true;
  }

  function parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      Buffer.from(base64, "base64")
        .toString()
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
}

export function getApiVersions() {
  const serviceVersions = SERVICES.map((item) => [
    baseClient.get("/" + item + "/version"),
  ]);
  const currentActiveApisVersions: any = [];
  return axios
    .all(serviceVersions)
    .then(
      axios.spread((...responses) => {
        responses.forEach((item, i) =>
          item[0].then((response) => {
            if (response.status === 200) {
              // access service key in response
              const serviceKey = Object.keys(response.data)[0];
              // acces service name
              const serviceName = SERVICES[i];
              currentActiveApisVersions.push([
                serviceName,
                response.data[serviceKey],
              ]);
            }
          })
        );
        return currentActiveApisVersions;
      })
    )
    .catch((error) => {
      console.info(error);
    });
}

/**
 * @returns true if the credentials are valid and logins successully, false otherwise
 */
export async function loginToApp(email: string, password: string) {
  try {
    const tokens = await login(email, password);
    localStorage.clear();
    localStorage.setItem("user_email", email);
    localStorage.setItem("access-token", tokens.access_token);
    localStorage.setItem("refresh-token", tokens.refresh_token);
    baseClient.defaults.headers.common["Authorization"] = tokens.access_token;
    return true;
  } catch (error) {
    console.info(error);
    return false;
  }
}

/**
 * @returns properties of all components
 */
export async function getPropertiesOfAll() {
  const componentStructure = store.getState().componentStructure.structure;
  const fetchedProperties: { [id: string]: GetValueAPIResponse } = {};
  const allRequests: Promise<AxiosResponse<GetValueAPIResponse>>[] = [];
  recursiveHelper(componentStructure);

  const responses = await axios.all(allRequests);
  responses.forEach((response) => {
    const requestBody = JSON.parse(response.config.data);
    fetchedProperties[String(requestBody.component_id)] = response.data;
  });
  return fetchedProperties;

  function recursiveHelper(components: (DeviceComponent | GroupComponent)[]) {
    for (const component of components) {
      fetchedProperties[String(component.id)] = {
        editable: [],
        not_editable: [],
      };
      allRequests.push(getValue(component.id));
      if (component.type === "group") recursiveHelper(component.childs);
    }
  }
}
