import { baseClient, updateAccessClient } from "./axiosConfig";
import {
  AuthAccessUpdateAPIResponse,
  AuthLoginAPIResponse,
} from "../types/iamResponseTypes";
import { AxiosResponse } from "axios";
import { GetPreferencesAPIResponse } from "../types/iamResponseTypes";

export async function login(
  email: string,
  password: string
): Promise<AuthLoginAPIResponse> {
  const { data } = await baseClient.post(
    process.env.REACT_APP_URL_AUTH_LOGIN!,
    { email, password }
  );
  return data;
}

export async function updateAccess(
  refresh_token: string
): Promise<AxiosResponse<AuthAccessUpdateAPIResponse>> {
  return await updateAccessClient.get(
    process.env.REACT_APP_URL_AUTH_ACCESS_UPDATE!,
    {
      headers: { Authorization: refresh_token },
    }
  );
}

export async function setPreferences(
  language: string,
  zone: string,
  topology?: string
) {
  if (topology) {
    return await baseClient.post(process.env.REACT_APP_URL_SET_PREFERENCES!, {
      language,
      zone,
      topology,
    });
  }

  return await baseClient.post(process.env.REACT_APP_URL_SET_PREFERENCES!, {
    language,
    zone,
  });
}

export async function getPreferences() {
  try {
    let response: AxiosResponse<GetPreferencesAPIResponse>;
    response = await baseClient.get(process.env.REACT_APP_URL_GET_PREFERENCES!);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
