import Navbar from "./layouts/Navbar";
import Main from "./layouts/Main";
import { useDispatch } from "react-redux";
import { prepareComponentStructure } from "../../utils/componentUtils";
import { setComponentStructure } from "../../features/componentStructureSlice";
import { useEffect, useState } from "react";
import { setComponentID } from "../../features/componentSlice";
import Footer from "./layouts/Footer";
import { Loading } from "../Loading";
import { setComponentStructureForFlow } from "../../features/topologySlice";
import ErrorPage from "../../pages/ErrorPage";
import { useNavigate } from "react-router-dom";
import { addAxiosInterceptors } from "../../services/axiosConfig";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    addAxiosInterceptors(navigate);
    prepareComponentStructure()
      .then((componentStructure) => {
        dispatch(setComponentStructure(componentStructure));
        dispatch(setComponentStructureForFlow(componentStructure));
        dispatch(setComponentID(componentStructure[0].id));
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, []); //eslint-disable-line

  if (isLoading) return <Loading variant="global" />;
  else if (isError) return <ErrorPage />;

  return (
    <div className="flex bg-dashboard min-h-screen">
      <Navbar />
      <div className="w-full xl:w-auto xl:flex-[1_1_0] overflow-x-clip">
        {/* <Topbar /> */}
        <Main />
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
