import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CallSplitOutlinedIcon from "@mui/icons-material/CallSplitOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import Monitoring from "../../pages/Monitoring/Monitoring";
import Topology from "../../pages/Topology/Topology";
import { Statistics } from "../../pages/Statistics/Statistics";
import { Reports } from "../../pages/Reports/Reports";
import { Disaggregation } from "../../pages/Disaggregation/Disaggregation";
import UserManagement from "../../pages/UserManagement/UserManagement";
import Preferences from "../../pages/Preferences";
import Details from "../../pages/Details/Details";
import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
import DetailsReport from "../../pages/DetailsReport";

export interface NavItemType {
  icon: React.ComponentType;
  text: string;
  path: string;
  component?: React.ComponentType;
}
export interface NavDataType {
  primaryPages: Array<NavItemType>;
  otherPages: Array<NavItemType>;
}

export const navData: NavDataType = {
  // order is important!
  // first primary page is also default page.
  primaryPages: [
    {
      icon: MonitorHeartOutlinedIcon,
      text: "Monitoring",
      path: "monitoring",
      component: Monitoring,
    },
    {
      icon: AccountTreeOutlinedIcon,
      text: "Topology",
      path: "topology",
      component: Topology,
    },
    {
      icon: InsightsOutlinedIcon,
      text: "Statistics",
      path: "statistics",
      component: Statistics,
    },
    {
      icon: ListAltOutlinedIcon,
      text: "Reports",
      path: "reports",
      component: Reports,
    },
    {
      icon: ListAltOutlinedIcon,
      text: "Details Report",
      path: "details-report",
      component: DetailsReport,
    },
    {
      icon: CallSplitOutlinedIcon,
      text: "Disaggregation",
      path: "disaggregation",
      component: Disaggregation,
    },
    {
      icon: ManageAccountsOutlinedIcon,
      text: "User Management",
      path: "user-management",
      component: UserManagement,
    },
    {
      icon: PageviewOutlinedIcon,
      text: "Details",
      path: "details",
      component: Details,
    },
  ],
  otherPages: [
    {
      icon: SettingsOutlinedIcon,
      text: "Preferences",
      path: "preferences",
      component: Preferences,
    },
    {
      icon: LogoutOutlinedIcon,
      text: "Logout",
      path: "/logout",
    },
  ],
};
