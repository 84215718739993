import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { GetValueAPIResponse } from "../../../types/ldmResponseTypes";
import {
  getAvailableParentGroupComponents,
  getAvailablePreMeterComponents,
  getComponentsList,
} from "../../../utils/componentUtils";
import { DeviceComponent, GroupComponent } from "../../../types/componentTypes";

interface Props {
  componentId: string;
  properties: GetValueAPIResponse;
  onPropertyChange: (event: any) => void;
  componentStructureForFlow: (DeviceComponent | GroupComponent)[];
}

/**
 * Returns a customized input element based on property name and whether
 * editable or not. Checks also whether it takes numeric value or text value.
 */
export const PropertiesForm = (props: Props) => {
  function getInputField(
    propName: string,
    propValue: string | number | null,
    isEditable: boolean
  ) {
    propValue = propValue ?? ""; // convert null to "" for input elements
    const key = `${props.componentId}-${propName}`;
    // Returns SelectBox to select an option among the allowed component ids
    if (propName === "pre_meter_id" || propName === "parent_group_id") {
      const componentsList = getComponentsList();
      const allOptions =
        propName === "pre_meter_id"
          ? componentsList.filter((c) => c.type !== "group")
          : componentsList.filter((c) => c.type === "group");
      const availableOptions =
        propName === "pre_meter_id"
          ? getAvailablePreMeterComponents(
              Number(props.componentId),
              props.componentStructureForFlow
            )
          : getAvailableParentGroupComponents(
              Number(props.componentId),
              props.componentStructureForFlow
            );
      return (
        <FormControl key={key} sx={{ my: 0.1 }} fullWidth>
          <InputLabel sx={{ left: -14, mt: 1 }}>{propName}</InputLabel>
          <Select
            onChange={props.onPropertyChange}
            key={key}
            fullWidth
            variant="standard"
            label={propName}
            name={propName}
            //@ts-ignore
            value={propValue}
          >
            {propName !== "parent_group_id" && (
              <MenuItem value={""} children={<em>none</em>} />
            )}
            {allOptions.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                disabled={!availableOptions.some((avOpt) => avOpt.id === id)}
                children={name ?? id}
              />
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return (
        <TextField
          onChange={props.onPropertyChange}
          key={key}
          name={propName}
          label={propName}
          size="small"
          variant="standard"
          fullWidth
          value={propValue}
          disabled={propName === "state" ? isEditable : !isEditable}
          inputProps={
            ["phase_number", "state", "reporter_id"].includes(propName)
              ? {
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }
              : undefined
          }
        />
      );
    }
  }

  return (
    <div className="flex flex-col gap-4">
      {getInputField("component_id", props.componentId, false)}
      {Object.entries(props.properties.editable).map(([propName, propValue]) =>
        getInputField(propName, propValue, true)
      )}
      {Object.entries(props.properties.not_editable).map(
        ([propName, propValue]) => getInputField(propName, propValue, false)
      )}
    </div>
  );
};
