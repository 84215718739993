import { useEffect, useRef, useState } from "react";
import { PageHeader } from "../../components/PageHeader";
import { baseClient } from "../../services/axiosConfig";
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { getComponentsList } from "../../utils/componentUtils";
import SearchBar from "../../components/SearchBar";
import CustomModal from "../../components/CustomModal";
import CreateUserForm from "./CreateUserForm";
import AddIcon from "@mui/icons-material/Add";

const columns = [
  "Features",
  "Group Rules",
  "Use Group Rules",
  "Allowed",
  "Denied",
];
const URL_USER_UPDATE = process.env.REACT_APP_URL_USER_UPDATE!;
const URL_USER_FIND = process.env.REACT_APP_URL_USER_FIND!;
const URL_USER_GET_PERMITTED_DEVICE =
  process.env.REACT_APP_URL_USER_GET_PERMITTED_DEVICE!;
const URL_USER_SET_PERMITTED_DEVICE =
  process.env.REACT_APP_URL_USER_SET_PERMITTED_DEVICE!;
const URL_USER_SET_RULE = process.env.REACT_APP_URL_USER_SET_RULE!;
const URL_GROUP_LIST = process.env.REACT_APP_URL_GROUP_LIST!;
const URL_FEATURE_LIST = process.env.REACT_APP_URL_FEATURE_LIST!;

const UserManagement = () => {
  const [email, setEmail] = useState(localStorage.getItem("user_email"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState<any>(null);
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const userGroups = useRef<
    Array<{ group_name: string; group_description: string }>
  >([]);
  const features = useRef<string[]>([]);
  const permittedDevices = useRef<{
    meter_ids: number[];
    meter_groups: number[];
  }>();

  useEffect(() => {
    fetch();
  }, [email]); //eslint-disable-line

  async function fetch() {
    setIsLoading(true);
    try {
      const [userRes, userGroupsRes, featuresRes] = await Promise.all([
        baseClient.post(URL_USER_FIND, { email }),
        baseClient.get(URL_GROUP_LIST),
        baseClient.get(URL_FEATURE_LIST),
      ]);
      const permittedDevicesRes = await baseClient.post(
        URL_USER_GET_PERMITTED_DEVICE,
        { uuid: userRes.data.uuid }
      );
      permittedDevices.current = permittedDevicesRes.data;
      userGroups.current = userGroupsRes.data;
      features.current = featuresRes.data;
      setUser(userRes.data);
      setIsError(false);
    } catch (error) {
      toast.error("User not found!");
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit(e: any) {
    let payload = { ...formData };
    try {
      // set rules if they changed
      if ("rules" in payload) {
        await baseClient.post(URL_USER_SET_RULE, {
          rules: payload.rules,
          uuid: user.uuid,
        });
        delete payload.rules;
      }
      // set permitted devices and device groups if they changed
      const permittedDevicesPayload = {
        ...(payload.meter_ids ? { meter_ids: payload.meter_ids } : {}),
        ...(payload.meter_groups ? { meter_groups: payload.meter_groups } : {}),
      };
      if (Object.keys(permittedDevicesPayload).length > 0) {
        await baseClient.post(URL_USER_SET_PERMITTED_DEVICE, {
          ...permittedDevicesPayload,
          uuid: user.uuid,
        });
        delete payload.meter_ids;
        delete payload.meter_groups;
      }
      // set other information
      await baseClient.post(URL_USER_UPDATE, { ...payload, uuid: user.uuid });
      toast.success("User details has been saved successfully.");
      setFormData({});
    } catch (error) {
      toast.error("A problem has been occured while saving user details.");
    }
  }

  function handleChange(e: any, key: string) {
    let newValue = e.target.value || undefined;
    let newFormData = { ...formData };
    if (user[key] === newValue) delete newFormData[key];
    else newFormData[key] = newValue;
    setFormData(newFormData);
  }

  function handleRadioChange(feature: string, permission: "r" | "a" | "d") {
    setFormData({
      ...formData,
      rules: {
        ...formData?.rules,
        [feature]: permission,
      },
    });
  }

  return (
    <div className="flex flex-col gap-2 lg:gap-4 items-center">
      <PageHeader
        title="User Management"
        element={
          <div className="flex flex-col lg:flex-row justify-center items-center gap-3">
            <SearchBar
              onSubmit={(value) => setEmail(value)}
              placeholder="user@linarit.com"
              width={350}
              defaultValue={email ?? ""}
            />
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              children="Create User"
              onClick={() => setIsModalOpen(true)}
            />
          </div>
        }
      />
      {isLoading ? (
        <Loading variant="global" />
      ) : isError ? null : (
        <div
          id="user-informataion"
          className="w-full max-w-lg card flex flex-col gap-6"
        >
          <div className="flex flex-col gap-3">
            <Typography
              variant="h6"
              fontWeight={400}
              children="User Information"
            />
            {Object.entries(user).map(([key, value]) =>
              key === "uuid" ? null : key === "group" ? (
                <FormControl key={key} size="small">
                  <InputLabel>Group</InputLabel>
                  <Select
                    defaultValue={value}
                    label="Group"
                    sx={{ bgcolor: "white" }}
                    size="small"
                    onChange={(e) => handleChange(e, key)}
                  >
                    {userGroups.current.map((ug, i) => (
                      <MenuItem key={i} value={ug.group_name}>
                        {ug.group_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : typeof value === "string" ? (
                <TextField
                  key={key}
                  label={key[0].toUpperCase() + key.slice(1)}
                  defaultValue={value}
                  onChange={(e) => handleChange(e, key)}
                  size="small"
                  InputProps={{ sx: { bgcolor: "white" } }}
                />
              ) : null
            )}
          </div>

          <div className="flex flex-col gap-3">
            <Typography
              variant="h6"
              fontWeight={400}
              children="Change Password"
            />
            <TextField
              type="password"
              label="New Password"
              size="small"
              InputProps={{
                sx: { bgcolor: "white" },
                autoComplete: "new-password",
              }}
              onChange={(e) => handleChange(e, "password")}
            />
            <TextField
              type="password"
              label="Confirm Password"
              size="small"
              InputProps={{ sx: { bgcolor: "white" } }}
              onChange={(e) => handleChange(e, "confirm_password")}
              error={formData.confirm_password !== formData.password}
            />
          </div>

          <div className="flex flex-col gap-3">
            <Typography
              variant="h6"
              fontWeight={400}
              children="Device Permissions"
            />
            <FormControl size="small">
              <InputLabel>Accessible Devices</InputLabel>
              <Select
                multiple
                defaultValue={permittedDevices.current!.meter_ids}
                label="Accessible Devices"
                sx={{ bgcolor: "white" }}
                onChange={(e) => handleChange(e, "meter_ids")}
                size="small"
              >
                {getComponentsList()
                  .filter((c) => c.type !== "group")
                  .map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.name ?? c.id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel>Accessible Device Groups</InputLabel>
              <Select
                multiple
                defaultValue={permittedDevices.current!.meter_groups}
                label="Accessible Device Groups"
                sx={{ bgcolor: "white" }}
                onChange={(e) => handleChange(e, "meter_groups")}
                size="small"
              >
                {getComponentsList()
                  .filter((c) => c.type === "group")
                  .map((c) => (
                    <MenuItem key={c.id} value={c.id}>
                      {c.name ?? c.id}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          <div className="overflow-x-auto flex flex-col gap-1">
            <Typography variant="h6" fontWeight={400} children="Rules" />
            <table>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th className="px-2 font-medium text-sm" key={column}>
                      {column}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {features.current.map((feature) => (
                  <tr className="text-center" key={feature}>
                    <td className="text-sm text-left">{feature} </td>
                    <td>
                      <Checkbox
                        size="small"
                        disabled
                        checked={user.group_rules[feature]}
                      />
                    </td>
                    <td>
                      <input
                        className="w-4 h-4"
                        type="radio"
                        name={feature}
                        value={"r"}
                        defaultChecked={user.user_rules[feature] === undefined}
                        onClick={() => handleRadioChange(feature, "r")}
                      />
                    </td>
                    <td>
                      <input
                        type="radio"
                        className="w-4 h-4"
                        name={feature}
                        value={"a"}
                        defaultChecked={user.user_rules[feature] === true}
                        onClick={() => handleRadioChange(feature, "a")}
                      />
                    </td>
                    <td>
                      <input
                        className="w-4 h-4"
                        type="radio"
                        name={feature}
                        value={"d"}
                        defaultChecked={user.user_rules[feature] === false}
                        onClick={() => handleRadioChange(feature, "d")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button
            variant="contained"
            onClick={handleSubmit}
            children={"Save Changes"}
            className="col-span-2"
            disabled={
              Object.keys(formData).length === 0 ||
              formData.confirm_password !== formData.password
            }
          />
        </div>
      )}
      <CustomModal
        title="Create New User"
        children={<CreateUserForm userGroups={userGroups.current} />}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </div>
  );
};

export default UserManagement;
