import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import linaritLogoWithText from "../../../assets/logo/linaritLogoWithText.png";
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { closeNavBar, toggleNavBar } from "../../../features/navBarSlice";
import { NavItemType, navData } from "../../../assets/data/navData";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useEffect, useState } from "react";
import { isMaxWidthXL } from "../../../utils/otherUtils";

const Navbar = () => {
  const isNavBarOpen = useSelector((state: RootState) => state.navBar.isOpen);
  const dispatch = useDispatch();
  const [showFoldButton, setShowFoldButton] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // When the path (page) is changed
  useEffect(() => {
    window.scrollTo(0, 0); // scrolls the page to the top
    if (isMaxWidthXL()) dispatch(closeNavBar()); // 1280px is xl width in tailwindCSS and MUI
  }, [location]); // eslint-disable-line

  function navListItem(pageData: NavItemType) {
    const isSelected = window.location.pathname === `/${pageData.path}`;
    return (
      <ListItem key={pageData.path} disablePadding sx={{ height: "3rem" }}>
        <ListItemButton
          selected={isSelected}
          onClick={() => navigate(pageData.path)}
          sx={{ borderRadius: "0.75rem" }}
        >
          <ListItemIcon
            sx={{ color: isSelected ? "primary.main" : "text.secondary" }}
            children={<pageData.icon />}
          />
          <ListItemText
            sx={{
              mt: 0.6,
              ml: -2,
              color: isSelected ? "primary.main" : "text.secondary",
            }}
            primary={pageData.text}
          />
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <nav
      id="navbar"
      onMouseEnter={() => setShowFoldButton(true)}
      onMouseLeave={() => setShowFoldButton(false)}
      className={
        (isNavBarOpen
          ? "w-[16rem] xl:w-auto xl:flex-[0_0_16rem] px-4 "
          : "w-0 xl:w-auto xl:flex-[0_0_3.5rem] px-0 ") +
        " h-screen fixed xl:sticky top-0 left-0 z-50" +
        " transition-all duration-300 overflow-hidden whitespace-nowrap " +
        " shadow-md bg-white flex flex-col border-x border-border"
      }
    >
      {/* Fold button */}
      <Button
        onClick={() => dispatch(toggleNavBar())}
        variant="contained"
        color={"white"}
        disableRipple
        sx={{
          position: "absolute",
          alignSelf: "center",
          right: 11,
          top: 16,
          padding: "3px",
          margin: 0,
          minWidth: "auto",
          borderRadius: 2,
          opacity: { xs: 1, xl: showFoldButton ? 1 : 0 },
          transitionProperty: "opacity",
          transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          transitionDuration: "300ms",
          transitionDelay: "100ms",
        }}
      >
        {isNavBarOpen ? (
          <NavigateBeforeIcon sx={{ fontSize: 26 }} />
        ) : (
          <NavigateNextIcon sx={{ fontSize: 26 }} />
        )}
      </Button>

      {/* brand logo */}
      <div className="w-full h-16 flex justify-center items-center">
        <img
          className="px-[0.96rem] w-auto h-1/2 object-cover object-left"
          src={linaritLogoWithText}
          alt="linarit-logo-with-text"
        />
      </div>

      <hr className={isNavBarOpen ? "mx-0" : "mx-2"} />

      <List sx={{ mt: 3, gap: 0.25, display: "flex", flexDirection: "column" }}>
        {navData.primaryPages.map((pageData) => navListItem(pageData))}
      </List>
      <Divider sx={{ flexGrow: 1 }} />
      <List sx={{ gap: 0.25, display: "flex", flexDirection: "column" }}>
        {navData.otherPages.map((pageData) => navListItem(pageData))}
      </List>
    </nav>
  );
};

export default Navbar;
