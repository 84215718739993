import dayjs from "dayjs";
import "dayjs/locale/tr";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import PrivateRoute from "./utils/PrivateRoute";
import Logout from "./pages/Logout";

function App() {
  useEffect(() => {
    dayjs.locale("tr");
  }, []); // eslint-disable-line

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route element={<PrivateRoute />}>
        <Route path="*" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}

export default App;
